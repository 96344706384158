import { useState, useEffect } from "react";
import { IconCode } from "@tabler/icons";
import { useQuery } from "@apollo/client";
import { useParams } from "react-router-dom";
import { Controlled as CodeMirror } from "react-codemirror2";
import { useFormik } from "formik";
import "codemirror/addon/display/placeholder";
import "codemirror/addon/display/autorefresh";
import { useIntl, FormattedMessage } from "react-intl";

import {
  Button,
  UtilityModal,
  Input,
  ModalBody,
  Tooltip,
  ToastNotification,
} from "./../../components";
import { copyStringToClipboard } from "./../../utils";

// Queries
import { FETCH_SITE } from "./../../graphql/query/site";

export const SiteCodeActionForm = ({ siteId }) => {
  const POLL_INTERVAL = 10000;
  const [verificationStatus, setVerificationStatus] = useState(false);
  const intl = useIntl()
  const verificationForm = useFormik({
    initialValues: {
      url: "",
    },
    onSubmit: async (values, formikForm) => {
      try {
        window.open(values.url, '_blank').focus();
        checkVerification();
      } catch (error) {
        formikForm.setErrors({
          url: error.graphQLErrors[0].message,
        });
      }
    },
    validate: (values) => {
      const errors = {};
      let domainRegex = new RegExp(`^(ftp|http|https):\/\/[^ "]+$`);

      if (!values.url) {
        errors.url = "Required";
      } else if (!domainRegex.test(values.url)) {
        errors.url = "Invalid URL";
      }

      return errors;
    },
  });
  
  const { loading, data } = useQuery(FETCH_SITE, {
    variables: {
      id: parseInt(siteId),
    },
    pollInterval: verificationStatus ? POLL_INTERVAL / 3 : 0,
  });
  
  useEffect(() => {
    verificationForm.setFieldValue("url", data?.site?.domain);
  }, [data, loading])
  
  
  

  const value = `<!-- Paste this code right before the </head> tag on every page
  of your site.--> 
  
  <script src="//${window.domains?.assets_domain}/app/v1/site.js"
      id="${window.parameterizedPartnerName}-script"
      data-${window.parameterizedPartnerName}-site-id="${siteId}"
      async="async">
  </script>`;

  const checkVerification = async () => {
    setVerificationStatus(true);

    setTimeout(() => {
      setVerificationStatus(false);
    }, POLL_INTERVAL + 1000);
  };

  return (
    <div>
      <div className="text-gray-800">
      {
        intl.formatMessage({
          id: "site_settings.site_code_action.modal.description",
          defaultMessage: `Paste the following code right before the </head> tag on every page of your site:`
        })
      }
      </div>

      <div className="rounded-[8px] mt-[16px] mb-[16px]">
        <Tooltip label="Click to copy!">
          <div
            onClick={() => {
              copyStringToClipboard(value);
              ToastNotification({
                id: new Date(),
                icon: "check",
                color: "success",
                body: "Script copied."
              });
            }}
          >
            <CodeMirror
              id="viewPixel"
              name="viewPixel"
              value={value}
              options={{
                lineNumbers: true,
                matchBrackets: true,
                lineNumbers: true,
                readOnly: true,
                showCursorWhenSelecting: false,
              }}
            />
          </div>
        </Tooltip>
      </div>

      <Input
        id="url"
        name="url"
        value={verificationForm.values.url}
        error={verificationForm.errors.url}
        onChange={verificationForm.handleChange}
        label="Input URL to verify"
        reactIntlId={"site_settings.site_code_action.modal.input_url.input"}
        className="w-full"
      />

      <div className="rounded-[12px] flex items-center justify-start flex-row-reverse py-[16px]">
        <Button
          label={verificationStatus ? "Loading..." : "Verify Code"}
          labelStyles="font-medium uppercase"
          disabled={verificationStatus}
          onClick={verificationForm.submitForm}
          reactIntlId={verificationStatus ? "global.button.loading" : "site_settings.site_code_action.modal.verify_code.button"}
        />
      </div>
    </div>
  );
};

export const SiteCodeAction = () => {
  const { siteId } = useParams();

  return (
    <>
      <Button
        height={40}
        label="Get site code"
        reactIntlId="site_settings.dashboard.get_site_code.button"
        className="uppercase"
        onClick={() => {
          UtilityModal({
            body: (props) => (
              <ModalBody
                action="primary"
                title={<FormattedMessage id="site_settings.site_code_action.modal.title" defaultMessage="Site Code" />}
                width="494px"
                handleClose={props.handleClose}
                closeOnSubmit={false}
                footer={false}
              >
                <SiteCodeActionForm siteId={siteId} />
              </ModalBody>
            ),
          });
        }}
      />
    </>
  );
};

export default SiteCodeAction;
