import { FC, ReactNode, useEffect } from 'react'
import { IconX } from '@tabler/icons';
import { Button } from '../Button'

interface button {
  label: string
  type?: string
  action?: string
  disabled?: boolean
  onClick: (...args: any[]) => void
  labelStyles?: string
  className?: string
  id?: string
  dataAttribute?: string 
  reactIntlId?: string
}

interface ModalBodyProps {
  buttons: button[]
  cancelButton?: boolean
  cancelButtonText?: string
  title: string | JSX.Element
  action: "default" | "primary" | "success" | "warning" | "danger"
  icon?: JSX.Element
  closeIcon?: boolean
  description?: string | ReactNode,
  onClose?: () => void
  handleClose?: () => void
  closeOnSubmit?: boolean
  handleEnter?: () => void
  footer?: boolean,
  footerBody?: Element,
  cn?: string
  isCustomBody?: boolean,
  closeIconDataAttribute?: string
}

const utilityTypes = {
  default: 'bg-gray-400 text-primary-light',
  primary: 'bg-primary-lighter text-primary-dark',
  success: 'text-success-dark bg-success-lighter',
  warning: 'bg-warning-light text-warning-dark',
  danger: 'bg-danger-light text-danger-dark',
}


export const ModalBody: FC<ModalBodyProps> = ({ 
  cn = 'pt-[30px]',
  buttons, 
  cancelButton, 
  cancelButtonText = "Cancel",
  title,
  isCustomBody = false,
  closeIcon = true,
  icon,
  action = "success",
  children,
  description,
  handleClose,
  closeOnSubmit,
  handleEnter,
  footer = true,
  footerBody,
  closeIconDataAttribute
}) => {

  useEffect(() => {
    const listener = (event: KeyboardEvent) => {
      if ((event.code === "Enter" || event.code === "NumpadEnter") && handleEnter) {
          event.preventDefault();
          handleEnter()
        }
      };
      
      document.addEventListener("keydown", listener);
      
      return () => document.removeEventListener("keydown", listener)
  }, [handleEnter]);

  return <div className={`flex flex-1 flex-col ${cn}`}>
          <div className="flex justify-between mx-[30px] relative">
            <div className={`flex flex-col`}>
              {
              icon && <div className={`${utilityTypes[action]} text-lg font-medium w-[40px] h-[40px] border border-white shadow-sm flex items-center justify-center rounded-full`}>
                {icon}
              </div>
              }
              <h1 className={`${icon && 'ml-[4px] mt-[8px]'} text-secondary max-w-[360px] overflow-ellipsis font-medium overflow-hidden whitespace-nowrap leading-[17.78px] text-[15px]`}>{title}</h1>
              {
                closeIcon && 
                <IconX 
                  className="cursor-pointer absolute right-[-2px] top-[24%] translate-y-[-50%] text-secondary" 
                  size="20px" 
                  stroke="1.25" 
                  onClick={() => handleClose && handleClose()}
                  {...(closeIconDataAttribute && {"data-attribute": `modal-close-${closeIconDataAttribute}`})}
                />
              }
            </div>
          </div>

          {!description && children && <div className={`${icon ? 'px-[34px] mt-[8px]' : `${!isCustomBody && 'px-[30px]'} ${title && 'mt-[24px]'}`}`}>{children}</div>}
          {description && !children && <div className={`${icon ? 'px-[34px] mt-[8px]' : 'px-[30px] mt-[24px]'} text-lg text-gray-900`}>{description}</div>}
          {description && children && <div className={`${icon ? 'px-[34px] mt-[8px]' : 'px-[30px] mt-[24px]'}`}>{children}</div>}

      { 
        footer && 
        <div style={{boxShadow:"0px -8px 16px rgba(0,0,0,0.04)"}} className="rounded-[12px] mt-[42px] flex items-center justify-start flex-row-reverse px-[24px] py-[16px]">
          {
            buttons && buttons?.length > 0 &&  buttons?.map(({ type, label, action, disabled, onClick, labelStyles = 'font-medium uppercase', className, id, dataAttribute, reactIntlId }, index) => 
            <Button 
              key={index} 
              disabled={disabled} 
              label={label} 
              type={type} 
              action={action} 
              labelStyles={labelStyles}
              className={className}
              id={id}
              reactIntlId={reactIntlId}
              onClick={async () => {
                onClick && await onClick()
                if(closeOnSubmit){
                  handleClose && handleClose()
                }
              }}
              {...(dataAttribute && { dataAttribute })}
              />
              )}
            {cancelButton && <Button className="mr-[8px]" dataAttribute={"Cancel"} labelStyles="font-medium text-secondary opacity-60 uppercase" label={cancelButtonText} type="link" action="default" onClick={() => handleClose && handleClose()} />}
        </div>
      }
      {
        footerBody && footerBody
      }
      </div>
}


ModalBody.defaultProps = {
  cancelButton: true,
  cancelButtonText: "Cancel",
  title: "Create New Modal",
  action: "primary",
  closeIcon: true,
  closeOnSubmit: true,
  footer: true
}