import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import PropTypes from "prop-types";
import { IconTrash } from "@tabler/icons";
import { useDropzone } from "react-dropzone";
import axios from "axios";
import { FormattedMessage } from "react-intl"
import { UtilityModal, ModalBody, ProgressBar } from '../../components';

export const FileUpload = ({
  title,
  subTitle,
  type,
  label,
  supportText,
  icon,
  assistiveText,
  minWidth,
  minHeight,
  maxWidth,
  maxHeight,
  preview,
  onUploadComplete,
  url,
  reactIntlTitleId,
  reactIntlSubTitleId,
  reactIntlAssistiveTextId,
  reactIntlSupportTextId
}) => {
  const [error, setError] = useState(false);
  const { siteId } = useParams();
  const [selectedFileUrl, setSelectedFileUrl ] = useState(url) 
  const [progress, setProgress] = useState(null);
  const config = {
    onUploadProgress: (progressEvent) => {
      setProgress(
        ((progressEvent.loaded / progressEvent.total) * 100).toFixed(2)
      );
    },
  };
  
  useEffect(() => {
    setSelectedFileUrl(url)
  }, [url])

  const onDrop = (acceptedFiles) => {
    try {
      if (acceptedFiles.length > 0) {
        const file = acceptedFiles[0];
        let _URL = window.URL || window.webkitURL;
        let img = new Image();
        img.onload = function () {
          if (this.width < minWidth) {
            setError(true);
          }
        };
        img.src = _URL.createObjectURL(file);
      }
    } catch (e) {
      console.log("error", e);
    }
  };
  
  const [file, setFile] = useState(null);
  const { getRootProps, getInputProps } = useDropzone({
    accept: "image/*",
    onDrop: (acceptedFiles) => {
      let currentFile = acceptedFiles[0];
      const image = new Image();
      image.onload = function() {
        if(!validateImageDimensions(this.width, this.height)) {
          setError(true);
        } else {
          setProgress(25);
          setFile(Object.assign(currentFile, {preview: URL.createObjectURL(currentFile)}))
          uploadImage(currentFile);
        }
      }
      
    
      image.src = URL.createObjectURL(currentFile);
    },
  });
  
  const getSignature = async (file, fileKey) => {
    const conditions = [
      { acl: "public-read" },
      { bucket: window.domains.aws_bucket},
      { "Content-Type": "image/"},
      { "success_action_status": "200" },
      { key: fileKey },
      { "x-amz-meta-qqfilename": file.name },
      ["content-length-range", "0", "50000000"],
    ];
    
    const postApiResponse = await axios.post(`//${window.appDomain}/uploads/signature`, { conditions })
    if(postApiResponse){
      const { data } = postApiResponse
      return {
        data,
        file,
        fileKey
      }
    }
  };
  
  const uploadImage = async (file) => {
    const fileKey = `site_assets/${siteId}/${new Date().getTime()}/${file.name.replaceAll(" ", "-").toLowerCase()}`
    const { data: signatureData } = await getSignature(file, fileKey)
    const { policy, signature } = signatureData
    const url = `https://${window.domains.aws_bucket}.s3.amazonaws.com`;
    
    const formData = new FormData();
    
    
    formData.set('key', fileKey)
    formData.set('AWSAccessKeyId',window.domains.aws_access_key_id)
    formData.set('Content-Type', file.type);
    formData.set('success_action_status', '200');
    formData.set('acl', 'public-read');
    formData.set('x-amz-meta-qqfilename', file.name);
    formData.set('policy', policy)
    formData.set('signature', signature)
    formData.set("file", file, file.name);

    
    const uploadFileResponse = await axios.post(url, formData, config);
    
    if(uploadFileResponse.status === 200){
      const uploadedFileUrl = `https://${window.domains.aws_bucket}.s3.amazonaws.com/${fileKey}`;
      setProgress(null)
      onUploadComplete(type, uploadedFileUrl);
    }
  }
  
  const renderDeleteAction = () => {
    return (
      <IconTrash
        size="16px"
        color="#DC3535"
        className="cursor-pointer"
        onClick={() => {
          UtilityModal({
            body: (props) => (
              <ModalBody
                title={`Delete ${type}?`}
                action="danger"
                icon={<IconTrash />}
                description={`You are about to delete ${type}. You will not be able to undo this change.`}
                handleClose={props.handleClose}
                closeOnEscape={false}
                buttons={[
                  {
                    label: "Delete",
                    action: "primary",
                    type: "default",
                    action: "danger",
                    onClick: () => {
                      onUploadComplete(type, "");
                    },
                  },
                ]}
              />
            ),
          })
        }}
      />
    );
  };
  
  const validateImageDimensions = (width, height) => {
    let status = false
    
    if(type === "logo" || type === 'image') {       
      if(minWidth && width > minWidth) {
        status = true;
      }
    }
    
    if(type === "favicon") {
      if(width >= minWidth && height >= minHeight && width <= maxWidth && height <= maxHeight) { 
        status = true;
      }
    }
    
    return status
  }
  
  return (
    <div>
      <div>
        <div className="text-sm text-secondary">
          {
            reactIntlTitleId ? <FormattedMessage id={reactIntlTitleId} defaultMessage={title}/> : title
          }{" "}
          <span className={`${error ? "text-danger" : "text-secondary-light"}`}>
            {reactIntlSubTitleId ? <FormattedMessage id={reactIntlSubTitleId} defaultMessage={subTitle}/> : subTitle}
          </span>
        </div>

        <div
          className={`border border-dashed ${
            error ? "border-danger" : ""
          } rounded-[4px] flex flex-col items-center justify-center h-[109px] my-[4px] cursor-pointer`}
        >
          {selectedFileUrl ? (
            <div
              className="w-[247px] h-[109px] bg-center bg-no-repeat bg-contain relative"
              style={{ backgroundImage: `url("${selectedFileUrl}")` }}
            >
              <span className="absolute right-[8px] top-[8px]">
                { renderDeleteAction()}
                {/* <IconTrash
                  size="16px"
                  color="#DC3535"
                  className="cursor-pointer"
                  onClick={() => {
                    setFile(null);
                    setSelectedFileUrl(null);
                  }}
                /> */}
              </span>
            </div>
          ) : (
            <div {...getRootProps({ onDrop, className: "dropzone" })}>
              <input {...getInputProps()} accept="image/png, image/jpeg" />
              <div className="flex flex-col items-center justify-center">
                <div className="text-center">{icon && icon}</div>
                <div className="text-xs text-secondary mt-[8px] mb-[4px]">
                  <FormattedMessage id="file_upload.drop_your_item.text" defaultMessage={`Drop your {title} here, or`} values={{ title: title.toLowerCase() }}/>{" "}
                  <span className="text-primary">
                    <FormattedMessage id="file_upload.drop_your_item.browse" defaultMessage={`browse`}/>
                  </span>
                </div>
                {
                  progress && <ProgressBar className="w-full" progress={progress ? `${progress}%` : "25%"}/>
                }
                <div className="text-xs text-normal text-secondary-light">
                  {supportText && reactIntlSupportTextId ? <FormattedMessage id={reactIntlSupportTextId} defaultMessage={supportText}/> : supportText}
                </div>
              </div>
            </div>
          )}
        </div>

        <div
          className={`text-xs ${
            error ? "text-danger" : "text-secondary-light"
          }`}
        >
          {error
            ? "Image should match the specified size"
            :
            reactIntlAssistiveTextId ? <FormattedMessage id={reactIntlAssistiveTextId} defaultMessage={assistiveText} /> : 
            assistiveText && assistiveText
          }
        </div>
      </div>
    </div>
  );
};

FileUpload.propTypes = {
  title: PropTypes.string,
  subTitle: PropTypes.string,
  label: PropTypes.string,
  icon: PropTypes.element,
  supportText: PropTypes.string,
  assistiveText: PropTypes.string,
  error: PropTypes.string,
  className: PropTypes.string,
  minWidth: PropTypes.number,
  reactIntlTitleId: PropTypes.string,
  reactIntlSubTitleId: PropTypes.string,
  reactIntlAssistiveTextId: PropTypes.string,
  reactIntlSupportTextId: PropTypes.string
};

FileUpload.default = {
  title: "Logo",
  subTitle: "(Min width 100px)",
  label: "logo",
  supportText: "Supports JPG or PNG files",
  icon: "",
  assistiveText: "You can drag your logo file here, or click to browse",
  error: "",
  className: "",
  reactIntlTitleId: "",
  reactIntlSubTitleId: "",
  reactIntlAssistiveTextId: "",
  reactIntlSupportTextId: ""
};

export default FileUpload;
