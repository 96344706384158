import PropTypes from "prop-types"
import { IconUsers } from "@tabler/icons"

import { kFormatter } from "../../utils"

const getScreenshotUrl = (url, type) => {
  let modifiedUrl = url
  if(type === "widget"){
    modifiedUrl = modifiedUrl.replace("crop/600", "crop/1000")
  }
  else {
    modifiedUrl = modifiedUrl.replace("width/250", "width/300/crop/1000")
  }

  return modifiedUrl
}

export const CampaignStat = ({ className, item = {} }) => {
  return(
    <div className={`h-[248px] rounded-[8px] ${className}`}
      style={{boxShadow: "0px 4px 4px rgba(17, 17, 17, 0.04)"}}
    >
      {
        item?.status?.toLowerCase() === "active" &&
        <div className="pb-[22px] break-words">
          <div
            className="h-[248px] rounded-[8px]"
            style={{
              backgroundImage: `url("${getScreenshotUrl(item?.screenshotUrl)}")`,
              backgroundSize: "cover",
              backgroundPosition: "top",
            }}
          />
          <div className="pt-[16px] justify-center">
            <div className="max-w-[200px] overflow-ellipsis overflow-hidden whitespace-nowrap text-secondary font-medium text-[15px] leading-[17.78px]">
              {item?.name}
            </div>
            <div className="flex pt-[8px]">
              <div className="bg-gray-200 w-[21px] h-[21px] rounded-[2px] flex items-center justify-center">
                <IconUsers className="text-gray-800 w-[19px] h-[19px]" />
              </div>
              <div className="text-secondary text-sm font-medium pl-[6px]">
                {kFormatter(item?.contactsCount)}
              </div>
            </div>
          </div>
        </div>
      }
    </div>
  )
}

CampaignStat.propTypes = {
  className: PropTypes.string,
  item: PropTypes.object
}

CampaignStat.defaultProps = {
  className: "",
  item: {}
}