import { IconLock } from '@tabler/icons'
import React from 'react'
import { Skeleton, Tooltip } from '..'
import { kFormatter } from '../../utils'

export interface StatsCardProps {
  number: string | number
  label: string
  icon?:  React.ReactElement
  percent?: number
  loading?: boolean
  biggerFont?: boolean
  locked?: boolean,
  largeBox?: boolean
  cn?: string
  onClick?: () => void
  dataAttribute?: string
}

export const StatsCard: React.FC<StatsCardProps> = ({ number, label, icon, percent, cn="h-[56px]", loading, biggerFont = false, locked = false, onClick, largeBox = false, dataAttribute }) => {

  const num = (typeof number === 'string' ? number : (kFormatter(number) || 0)) || 0
  return loading ? 
    <Skeleton cn="mr-2" width="100%" height="100%" /> 
    :
    <Tooltip label={`${!locked && typeof number === 'number' && number >= 1000 ? number.toLocaleString() : ""}`}>
      <div 
        onClick={onClick} 
        className={`overflow-hidden relative w-auto rounded-[8px] ${largeBox ? 'px-[20px]' : 'px-[16px] py-[12px]'} bg-gray-200 flex ${icon ? 'flex-row items-center justify-between' : 'flex-col items-start justify-center'} uppercase ${cn}`}
        {...(dataAttribute && {'data-attribute': `stats-card-${dataAttribute}`})}
      >
        <div style={{ width: `${percent}%` }} className="absolute bg-primary-lighter h-16 left-[0px]" />
        <div className={`${percent && 'z-10'}`}>
          <div className={`${biggerFont ? 'text-[40px] font-light' : 'text-secondary text-lg font-normal'} ${locked && 'mr-1'}`}>
            {!locked ? num : <IconLock size="20px" className="relative right-[3px] bottom-1" />}
          </div>
          <div className={`${largeBox ? "text-[15px] leading-[20px] text-gray-800 capitalize mb-[16px]" : biggerFont ? "text-lg":"text-xs"}  text-gray-800`}>{label}</div>
        </div>
          {
            icon && 
            <div className={`ml-[16px] ${percent && 'z-10'}`}>
              {icon}
            </div>
          }
      </div>
    </Tooltip>
}