import React from 'react'
import { IconPlayerPlay } from '@tabler/icons'

export interface FilterProps {
  icon: JSX.Element
  postIcon?: JSX.Element
  label: string
  isActive: boolean
  iconPosition?: "left" | "right"
  cn?: string
  onClick?: () => void,
  type?: string
  dataAttribute?: string
}

export const Filter: React.FC<FilterProps> = ({ postIcon = '', icon, iconPosition = "left", label, isActive = false, onClick, cn, type = 'primary', dataAttribute = '' }) => {
  return (
    <div data-Attribute={`filter-${dataAttribute}`} className={`${isActive ? `bg-primary-lighter text-primary ${type === 'default' && 'border-[1px] border-primary-lighter'}` : `${type === 'default' ? 'hover:bg-gray-500 text-gray-900 border-[1px]' : 'bg-gray-400 hover:bg-gray-500 text-gray-900'}`} flex items-center justify-between text-sm rounded text-center cursor-pointer ${cn}`}>
      <div onClick={onClick} className='flex px-[10px] py-[3px]'>
        {
          icon &&
            <div className={`flex justify-center items-center ${label && iconPosition === "left" && 'mr-[8px]'}  ${label && iconPosition === "right" && 'ml-[8px]'}`}>
              {icon}
          </div>
        }
        {label && <span className="mt-[1px]">{label}</span>}
      </div>
      {postIcon && <div className="pr-[10px] mb-[3px]">{postIcon}</div>}
    </div>
  )
}

Filter.defaultProps = {
  type: 'primary',
  label:'Ongoing',
  icon: <IconPlayerPlay size="16px" />,
  isActive: false,
  iconPosition: 'left'
}